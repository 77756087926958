const React = require('react');
const { Helmet } = require('react-helmet');

const isExcluded = (excludes, element) => {
    if (!Array.isArray(excludes))
        return false;

    element = element.replace(/\/+$/, '');

    return excludes.some(exclude => {
        if (exclude instanceof RegExp)
            return element.match(exclude);
        return exclude.includes(element);
    });
};

const getPathName = (noTrailingSlash, pathName) => {
    let pathname = pathName || '/';

    if (noTrailingSlash && pathname.endsWith('/')) {
        pathname = pathname.substring(0, pathname.length - 1);
    }

    return pathname;
};

// cloned and edit code from https://github.com/NickCis/gatsby-plugin-react-helmet-canonical-urls
module.exports = ({ element, props }, pluginOptions) => {
    if (
        pluginOptions &&
        pluginOptions.siteUrl &&
        !isExcluded(pluginOptions.exclude, props.location.pathname)
    ) {
        let pathname = getPathName(pluginOptions.noTrailingSlash, props.location.pathname);

        const myUrl = `${pluginOptions.siteUrl}${pathname.endsWith('/') ? pathname : pathname + '/'}${props.location.search}${
            props.location.hash
        }`;

        let alternateMetas = [];
        if (pluginOptions.locales && props.pageContext) {
            const defaultLocale = pluginOptions.locales.find(l => l.default);

            alternateMetas = pluginOptions.locales
                .map(l => {
                    const isDefaultLocale = l.locale === defaultLocale.locale;
                    const route = (isDefaultLocale && props.pageContext.originalPath === '/')
                        ? '/' : (pathname === '/') ? `/`
                            : `${l.routes[getPathName(true, props.pageContext.originalPath) || '/']}`;

                    return {
                        rel: 'alternate',
                        href: `${l.siteUrl}${getPathName(pluginOptions.noTrailingSlash, route)}${props.location.search}${
                            props.location.hash
                        }`,
                        hreflang: l.locale,
                    }
                });
            alternateMetas.push({
                rel: 'alternate',
                href: myUrl,
                hreflang: 'x-default',
            });
        }

        return (
            <>
                <Helmet
                    link={[
                        {
                            rel: 'canonical',
                            href: myUrl,
                        },
                    ].concat(alternateMetas)}
                />
                {element}
            </>
        );
    }

    return element;
};
