import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';

import LocalizedLink from '../LocalizedLink/LocalizedLink';
import './CookieBanner.scss';

// NOTE: https://techcrunch.com/2020/05/06/no-cookie-consent-walls-and-no-scrolling-isnt-consent-says-eu-data-protection-body/
const CookieBanner = (callback, deps) => {
    const { t } = useTranslation();
    const key = 'schedulino-cookie-agreement';
    const [hasAcceptedCookies, setHasAcceptedCookies] = useState(undefined);
    // const [listeningScroll, setListeningScroll] = useState(false);

    // const onScroll = useCallback(() => {
    //     const dismissOnScrollThreshold = 400;
    //     if (window.pageYOffset > dismissOnScrollThreshold) {
    //         // onAccept()
    //         setHasAcceptedCookies(true);
    //         localStorage.setItem(key, 'agreed');
    //     }
    // }, []);

    // const removeOnScrollListener = useCallback(() => {
    //     if (listeningScroll) {
    //         if (window.detachEvent) {
    //             // Internet Explorer
    //             window.detachEvent('onscroll', onScroll);
    //         } else if (window.removeEventListener) {
    //             window.removeEventListener('scroll', onScroll, false);
    //         }
    //         setListeningScroll(false);
    //     }
    // }, [listeningScroll, onScroll])

    const onAccept = /*useCallback(*/() => {
        setHasAcceptedCookies(true);
        localStorage.setItem(key, 'agreed');

        // if (listeningScroll) {
        //     removeOnScrollListener();
        // }
    }/*, [listeningScroll, removeOnScrollListener])*/

    // const addOnScrollListener = useCallback(() => {
    //     if (!listeningScroll && !hasAcceptedCookies) {
    //         if (window.attachEvent) {
    //             // Internet Explorer
    //             window.attachEvent('onscroll', onScroll);
    //         } else if (window.addEventListener) {
    //             window.addEventListener('scroll', onScroll, false);
    //         }
    //         setListeningScroll(true);
    //     }
    // }, [hasAcceptedCookies, listeningScroll, onScroll])


    useEffect(() => {
        if (localStorage) {
            // Safari with privacy options will have localStorage
            // but won't let us write to it.
            setHasAcceptedCookies(localStorage.getItem(key));
        }

        // if (!hasAcceptedCookies) {
        //     addOnScrollListener();
        // }

        return () => {
            // before component unmounts, do this
            // removeOnScrollListener()
        }
    }, [hasAcceptedCookies, /*addOnScrollListener, removeOnScrollListener*/]);

    return (
        <>
            {
                !hasAcceptedCookies
                    &&
                    <section className="cookie-banner">
                        <div className="cookie-banner-container">
                            <span>{t('cookieBanner.text')} <LocalizedLink to="/cookies" onClick={onAccept}>{t('cookieBanner.learnMore')}</LocalizedLink></span>
                            <button className="btn btn-sm btn-success ml-3" onClick={onAccept}>{t('cookieBanner.gotIt')}</button>
                        </div>
                    </section>
            }
        </>
    )
};

export default CookieBanner;
