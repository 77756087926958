import React from "react"

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import CookieBanner from "../components/CookieBanner/CookieBanner"
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import withI18next from "../i18n/withI18next"

import "../styles/style.scss"

const Layout = ({ children }) => {
  return (
    <ErrorBoundary>
      <Header />
      <main>{children}</main>
      <Footer />
      <CookieBanner />
    </ErrorBoundary>
  )
};

export default withI18next()(Layout)
