import React from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

import logo from '../../assets/images/logos/logo-transparent-icon.png';
import { getSignUpLink } from '../../i18n';
import LocalizedLink from '../LocalizedLink/LocalizedLink';
import './Footer.scss';

const Footer = () => {
    const { t, i18n } = useTranslation();
    const copyrightDate = new Date().getFullYear();

    return (
        <>

            <section className="text-center py-5 bg-white position-relative">
                <div className="custom-shape-divider-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path
                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                            fill="#fff"></path>
                    </svg>
                </div>
                <div className="container">
                    <h3>{t('footer.signUpHeader')}</h3>
                    <p>{t('footer.signUpSubheaderHeader')}</p>
                    <a href={getSignUpLink(i18n.language)} className="btn btn-success btn-lg mt-3">{t('label.signUpButton')}</a>
                </div>
            </section>

            <footer className="position-relative">
                <div className="custom-shape-divider-top">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path
                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                            fill="#fff"></path>
                    </svg>
                </div>
                <div className="container pt-4">
                    <div className="row pt-5 upper-footer">
                        <div className="col-md-3">
                            <LocalizedLink to="/" className="navbar-brand">
                                <img
                                    className="logo-icon d-inline-block align-top"
                                    alt="Schedulino"
                                    src={logo}
                                    width="40"
                                    height="40"
                                />&nbsp;Schedulino
                            </LocalizedLink>
                            <p className="mt-2">{t('footer.productDescription')}</p>
                        </div>
                        <hr className="d-md-none"/>
                        <div className="offset-md-2 col-md-2">
                            <h6 className="text-white mb-3">{t('footer.product')}</h6>
                            <ul className="nav flex-column">
                                {/*<li className="nav-item">*/}
                                {/*    <LocalizedLink to="/features" className="nav-link">{t('navigation.pricing')}</LocalizedLink>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    <LocalizedLink to="/pricing" className="nav-link">{t('navigation.pricing')}</LocalizedLink>
                                </li>
                                <li className="nav-item">
                                    <LocalizedLink to="/contact" className="nav-link">{t('navigation.contact')}</LocalizedLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <h6 className="text-white mb-3">{t('footer.legal')}</h6>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <LocalizedLink to="/privacy" className="nav-link">{t('navigation.privacy')}</LocalizedLink>
                                </li>
                                <li className="nav-item">
                                    <LocalizedLink to="/terms" className="nav-link">{t('navigation.terms')}</LocalizedLink>
                                </li>
                                <li className="nav-item">
                                    <LocalizedLink to="/cookies" className="nav-link">{t('navigation.cookies')}</LocalizedLink>
                                </li>
                            </ul>
                        </div>
                        {/*<div className="col-md-2">*/}
                        {/*    <h6 className="text-white mb-3">{t('footer.company')}</h6>*/}
                        {/*    <ul className="nav flex-column">*/}
                        {/*        <li className="nav-item">*/}
                        {/*            About*/}
                        {/*        </li>*/}
                        {/*        <li className="nav-item">*/}
                        {/*            Careers*/}
                        {/*        </li>*/}
                        {/*        <li className="nav-item">*/}
                        {/*            <LocalizedLink to="/contact" className="nav-link">{t('navigation.contact')}</LocalizedLink>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        <div className="col-md-3">
                            <h6 className="text-white mb-3">{t('footer.contactUs')}</h6>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link" href="mailto:support@schedulino.com">
                                        <FontAwesomeIcon icon={faEnvelope} fixedWidth className="mr-1" />support@schedulino.com</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="tel:+35316994325" target="_blank">
                                        <FontAwesomeIcon icon={faPhoneAlt} fixedWidth className="mr-1" />+353 1 699 4325</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.google.com/maps/place/Schedulino/@53.3347868,-6.2457258,17z/data=!3m1!4b1!4m5!3m4!1s0x48670f063ec7fbbf:0xb4af842c917a5230!8m2!3d53.3347868!4d-6.2457258?hl=en" target="_blank" rel="noreferrer" title="View on Google Maps">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth className="mr-1" />71 Baggot Street Lower, Dublin, D02 P593, Ireland</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row lower-footer">
                        <hr />
                        <div
                            className="col-md-6 d-flex align-items-center justify-content-md-start justify-content-center">
                            <span>&copy; {copyrightDate} Schedulino Ltd. {t('footer.allRightReserved')}</span>
                        </div>
                        <div className="col-md-6 d-flex justify-content-md-end justify-content-center social">
                            <a href="https://www.facebook.com/schedulino" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FontAwesomeIcon icon={faFacebookSquare} fixedWidth size="lg" /></a>
                            {/* <a href="https://www.instagram.com/schedulino" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><i class="fa fa-instagram fa-fw"></i></a */}
                            <a href="https://twitter.com/schedulino" target="_blank" rel="noopener noreferrer" aria-label="Twitter"><FontAwesomeIcon icon={faTwitter} fixedWidth size="lg" /></a>
                            <a href="https://www.youtube.com/channel/UCSrPRbacPXjzAZqICBfRKJQ" target="_blank" rel="noopener noreferrer" aria-label="Youtube"><FontAwesomeIcon icon={faYoutube} fixedWidth size="lg" /></a>
                            <a href="https://www.linkedin.com/company/schedulino" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><FontAwesomeIcon icon={faLinkedin} fixedWidth size="lg" /></a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
};

export default Footer;
