import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from "react-i18next";

import SEO from "../Seo/Seo";

/*global Sentry*/
class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });
        Sentry.configureScope((scope) => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
        });
        Sentry.captureException(error);
    }

    render() {
        if (this.state.error) {
            // render fallback UI
            return (
                <>
                    <SEO
                        title={this.props.t("meta.500.title")}
                        lang={this.props.i18n.language}
                    />
                    <div className="Privacy">
                        <section className="mask-3-light">
                            <div className="container text-center">
                                <h1 className="text-white">Oops...</h1>
                                <p>{this.props.t("error500.subtitle")}</p>
                            </div>
                        </section>
                    </div>
                </>
            )
        } else {
            // when there's not an error, render children untouched
            return this.props.children;
        }
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default withTranslation()(ErrorBoundary);
