import React, { useContext } from 'react'
import { Link } from 'gatsby';

import LocaleContext from '../../context/localeContext';
import { getLocalizedPath } from '../../i18n';

// Use the globally available context to choose the right path
const LocalizedLink = ({ to, ...props }) => {
    const { locale } = useContext(LocaleContext);
    // const language = languages.find(l => l.locale === locale);
    // const isIndex = to === `/`;

    // If it's the default language, don't do anything
    // If it's another language, add the 'path'
    // However, if the homepage/index page is linked don't add the 'to'
    // Because otherwise this would add a trailing slash
    // const path = language.default
    //     ? to
    //     : getLocalizedPath(to, locale);

    return <Link {...props} to={getLocalizedPath(to, locale)} activeClassName="active" />;

    // {({ locale }) => <Link {...props} to={getLocalizedPath(to, locale)} />}

};

export default LocalizedLink;
