/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { languages } from '../../i18n';

function SEO({ description, lang, meta, title, pathname }) {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author,
            siteUrl
          }
        }
      }
    `
    );

    const metaDescription = description || site.siteMetadata.description;
    const currentLanguage = languages.find(l => l.locale === lang);
    const languagesWithoutCurrent = languages.filter(l => l.locale !== lang);
    const imageUrl = `${site.siteMetadata.siteUrl}/static/schedulino_grid.png?v1`;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s - ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:image`,
                    content: imageUrl,
                },
                {
                    property: `og:image:alt`,
                    content: title,
                },
            ].concat([
                /* Open Graph */
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:site_name`,
                    content: site.siteMetadata.title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: imageUrl,
                },
                {
                    property: `og:locale`,
                    content: currentLanguage && currentLanguage.culture,
                },
                {
                    property: 'og:url',
                    content: site.siteMetadata.siteUrl + pathname
                }
            ]).concat(languagesWithoutCurrent.map(lang => ({
                property: `og:locale:alternate`,
                content: lang.culture,
            }))).concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default SEO;
