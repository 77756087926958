const languages = require('../config/languages');
const languagesMap = {
    'en': languages.find(l => l.locale === 'en'),
    'cs': languages.find(l => l.locale === 'cs'),
    'sk': languages.find(l => l.locale === 'sk')
};
const defaultLanguage = languages.find(lang => lang.default);

if (!defaultLanguage) {
    throw new Error(
        "Default language has to be specified in the language configuration."
    );
}

const getDomainPath = (localizedPath, locale) => {
    switch(locale) {
        case 'en':
            return `${languagesMap['en'].siteUrl}${localizedPath}`;
        case 'sk':
            return `${languagesMap['sk'].siteUrl}${localizedPath}`;
        case 'cs':
            return `${languagesMap['cs'].siteUrl}${localizedPath}`;
        default:
            return '/';
    }
}

const getLocalizedPath = (originalPath, locale) => {
    const keyPath = originalPath && originalPath.replace(/(\w+)\/$/, "$1");
    const lang = languages.find(lang => lang.locale === locale);

    const isDefault = locale === defaultLanguage.locale;
    const localizedPath = (lang && lang.routes && lang.routes[keyPath]) || '/404';

    if (!localizedPath) {
        throw new Error(
            `You have to specify a translation for all of your routes.\n There is no transalation for ${originalPath} in language ${locale}.`
        );
    }
    return isDefault ? localizedPath : getDomainPath(localizedPath, locale);
};

module.exports = getLocalizedPath;
