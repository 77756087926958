import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Container, Navbar, Nav } from 'react-bootstrap';

import logo from '../../assets/images/logos/logo-transparent-icon.png';
import { getSignUpLink } from '../../i18n';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import LocalizedLink from '../LocalizedLink/LocalizedLink';

import './Header.scss';

const Header = () => {
    const { i18n, t } = useTranslation();
    const [navExpanded, setNavExpanded] = useState(false);

    const closeNav = () => {
        setNavExpanded(false);
    };

    return (
        <header>
            <Navbar onToggle={setNavExpanded}
                    expanded={navExpanded} variant="dark" expand="lg">
                <Container>
                    <LocalizedLink to="/" className="navbar-brand" onClick={closeNav}>
                        <img
                            className="logo-icon align-top"
                            alt="Schedulino"
                            src={logo}
                            width="50"
                            height="50"
                        />&nbsp;Schedulino
                    </LocalizedLink>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto ml-md-4">
                            <Nav.Item>
                                <LocalizedLink to="/pricing" className="nav-link" onClick={closeNav}>{t('navigation.pricing')}</LocalizedLink>
                            </Nav.Item>
                            <Nav.Item>
                                <LocalizedLink to="/contact" className="nav-link" onClick={closeNav}>{t('navigation.contact')}</LocalizedLink>
                            </Nav.Item>
                        </Nav>
                        <Nav>
                            <LanguageSwitcher />
                            <a className="btn btn-outline-secondary mr-md-2" href="https://app.schedulino.com">{t('navigation.login')}</a>
                            <a className="btn btn-success" href={getSignUpLink(i18n.language)}>{t('navigation.signUp')}</a>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
};

export default Header;
