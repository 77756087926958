module.exports = [
    {
        locale: "en",
        culture: 'en_US',
        label: "English",
        siteUrl: "https://schedulino.com",
        routes: {
            "/": "/",
            "/pricing": "/pricing/",
            "/contact": "/contact/",
            "/privacy": "/privacy/",
            "/terms": "/terms/",
            "/cookies": "/cookies/",
            "/404": "/404/",
            "/404.html": "/404.html"
        }
    },
    {
        locale: "cs",
        label: "Čeština",
        culture: 'cs_CZ',
        siteUrl: "https://schedulino.cz",
        routes: {
            "/": "/",
            "/pricing": "/cenik/",
            "/contact": "/kontakt/",
            "/privacy": "/ochrana-soukromi/",
            "/terms": "/podminky-pouzivani/",
            "/cookies": "/cookies/",
            "/404": "/404/",
            "/404.html": "/404.html"
        }
    },
    {
        locale: "sk",
        label: "Slovenčina",
        culture: 'sk_SK',
        siteUrl: "https://schedulino.sk",
        routes: {
            "/": "/",
            "/pricing": "/cennik/",
            "/contact": "/kontakt/",
            "/privacy": "/ochrana-sukromia/",
            "/terms": "/podmienky-pouzivania/",
            "/cookies": "/cookies/",
            "/404": "/404/",
            "/404.html": "/404.html"
        }
    }
].map(l => l.locale === process.env.LANG ? Object.assign(l, {default: true}) : l);
