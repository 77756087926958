module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-react-helmet-canonical-urls-i18n/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://schedulino.com","locales":[{"locale":"en","culture":"en_US","label":"English","siteUrl":"https://schedulino.com","routes":{"/":"/","/pricing":"/pricing/","/contact":"/contact/","/privacy":"/privacy/","/terms":"/terms/","/cookies":"/cookies/","/404":"/404/","/404.html":"/404.html"},"default":true},{"locale":"cs","label":"Čeština","culture":"cs_CZ","siteUrl":"https://schedulino.cz","routes":{"/":"/","/pricing":"/cenik/","/contact":"/kontakt/","/privacy":"/ochrana-soukromi/","/terms":"/podminky-pouzivani/","/cookies":"/cookies/","/404":"/404/","/404.html":"/404.html"}},{"locale":"sk","label":"Slovenčina","culture":"sk_SK","siteUrl":"https://schedulino.sk","routes":{"/":"/","/pricing":"/cennik/","/contact":"/kontakt/","/privacy":"/ochrana-sukromia/","/terms":"/podmienky-pouzivania/","/cookies":"/cookies/","/404":"/404/","/404.html":"/404.html"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://3c8a56b8982e4baf861d5e5dfa35e8a8@sentry.io/1829255","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NZQLLQG","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Schedulino","short_name":"Schedulino","start_url":"/?utm_source=homescreen","background_color":"#343a40","theme_color":"#343a40","display":"standalone","icon":"src/assets/images/logos/logo-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3939fb775527697e2bfcdbd5ba473589"},
    }]
